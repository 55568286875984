"use strict";


class pxNavigationToogle {


  constructor (navigationSelector, settings = {}) {

    this.defaults = {
      'prefix' : 'px',
      'selector' : 'ul ul',
      'hiddenAttribute' : 'aria-hidden'
    };

    // Config erstellen
    this.config = Object.assign(this.defaults,settings);

    // Navigation
    this.navigation = document.querySelector(navigationSelector);

    if ( !this.navigation ) { return }
    
    // Aria-Labels des Buttons
    this.labelOpen = this.navigation.getAttribute('data-label-open');
    this.labelClose = this.navigation.getAttribute('data-label-close');

    // Text des Button
    this.textOpen = this.navigation.getAttribute('data-text-open');
    this.textClose = this.navigation.getAttribute('data-text-close');

    // Listen suchen
    this.navigationSublists = this.navigation.parentNode.querySelectorAll(this.config.selector);
    let index = 0;
    
    // Button und Funktionen einfügen
    for( index=0; index < this.navigationSublists.length; index++ ) {

      var list = this.navigationSublists[index];
      var link = list.previousElementSibling;

      var linkID = this.config.prefix + index + 'link';
      var listID = this.config.prefix + index;
      var btnID = this.config.prefix + index + 'btn';
      
      link.setAttribute('id',linkID);

      var button = document.createElement('button');
      button.innerHTML = this.textOpen;
      this.setAttributes(button, {
          'aria-controls': listID,
          'aria-expanded': 'false',
          'aria-label': this.labelOpen,
          'class': 'js-btn',
          'id': btnID,
      });
      button.addEventListener('click', (event) => {
        this.toggleAriaStates(event);
      }, false);
      list.parentNode.insertBefore(button,list);

      this.setAttributes(list,{
        [this.config.hiddenAttribute]: true,
        'id': listID,
      });
      
      
    }

  }


  setAttributes (element, attributes) {
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
  }


  toggleAriaStates (event) {

    event.preventDefault();

    var button = event.target;
    var list = document.getElementById( button.getAttribute('aria-controls') );

    var expanded = false;
    var buttonText = this.textClose;

    if ( button.getAttribute('aria-expanded') === 'true' ) {
      expanded = true;
      buttonText = this.textOpen;
    }

    list.setAttribute(this.config.hiddenAttribute,expanded);

    this.setAttributes(button,{
      'aria-label': expanded ? this.labelOpen : this.labelClose,
      'aria-expanded': !expanded
    });

    button.innerHTML = buttonText;

  };


}


export default pxNavigationToogle;

