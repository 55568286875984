"use strict";


class pxUserSetting {


  constructor (selector, settings = {}) {

    this.defaults = {
      'name' : 'floppy',
      'value' : 1,
      'expireDays' : 30
    };

    // Config erstellen
    this.config = Object.assign(this.defaults,settings);

    let elements = document.querySelectorAll(selector);

    for (let i = 0; i < elements.length; i++) {

      elements[i].addEventListener('click', event => {

        event.preventDefault();
        
        if (this.getCookie(this.config.name) == this.config.value) {
          this.eraseCookie(this.config.name);
        } else {
          this.setCookie(this.config.name, this.config.value, this.config.expireDays);
        }
        
      });

    }

  }


  setCookie(name,value,days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }


  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }


  eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }


}


export default pxUserSetting;

