


// ------------------------------------------
// komplexe Leaflet-Karte
ready(function() {

  let mapCE = document.querySelectorAll('.map-with-marker');

  for (let ce = 0; ce < mapCE.length; ce++) {

    let rootContainer = mapCE[ce];
    let categoryListContainer = rootContainer.querySelectorAll('.map-with-marker-categorie');


    // Title-Suffix
    let markerTitleSuffix = rootContainer.getAttribute('data-marker-title-suffix');


    //
    // Zielcontainer für Karte erstellen und im Dom einfügen
    let mapContainer = document.createElement('div');
    mapContainer.id = 'map-extended' + ce;

    rootContainer.insertBefore(mapContainer, rootContainer.firstChild);


    //
    // Layer und Marker erstellen
    let l;
    let layers = [];
    let controlLayers = {};
    let markers = L.markerClusterGroup.layerSupport();
    for (l = 0; l < categoryListContainer.length; l++) {

      let markerItems = categoryListContainer[l].querySelectorAll('li[data-lat]');

      if ( markerItems.length > 0 ) {

        // Texte und Daten auslesen
        let categoryTitle = categoryListContainer[l].getAttribute('data-category-title');
        let categoryIcon = categoryListContainer[l].getAttribute('data-icon');
        let categoryIconWidth = categoryListContainer[l].getAttribute('data-icon-width');
        let categoryIconHeight = categoryListContainer[l].getAttribute('data-icon-height');
        let categoryColor = categoryListContainer[l].getAttribute('data-color');

        // Layer erstellen
        layers[l] = L.layerGroup();
        markers.checkIn(layers[l]);

        // Layer im Config-Array für den Filter speichern
        controlLayers['<svg viewBox="0 0 16 16" aria-hidden="true"><circle cx="8" cy="8" r="8"/><circle fill="'+categoryColor+'" cx="8" cy="8" r="4"/></svg> '+categoryTitle] = layers[l];

        // Icon
        let icon = L.icon({
          iconUrl: categoryIcon,
          iconSize: [categoryIconWidth, categoryIconHeight]
        });

        let m;
        for (m = 0; m < markerItems.length; m++) {

          let markerLon = markerItems[m].getAttribute('data-lon');
          let markerLat = markerItems[m].getAttribute('data-lat');
          let markerTitle  = markerItems[m].getAttribute('data-title');

          let markerObj = L.marker([markerLat, markerLon],{
            icon: icon,
            alt: markerTitle + ' ' + markerTitleSuffix
          }).bindPopup(markerItems[m].innerHTML).addTo(layers[l]);
          markerObj.addEventListener('click',function(event){
            this.openPopup();
          });

        }

      }

    }


    //
    // Callback-Funktionen
    let mapOnLoad = function (event) {
      
      let controlsContainer = mapContainer.querySelector('.leaflet-control-layers-overlays');
      let controlsLabel = mapContainer.querySelector('.leaflet-control-layers-overlays label input');

      if (controlsLabel) {
        controlsContainer.id = mapContainer.id + '-controls';
        let skipToControls = document.createElement('a');
        skipToControls.innerHTML = leaflet.skipToControls;
        skipToControls.href = '#' + mapContainer.id + '-controls';
        skipToControls.classList.add('leaflet-skip-to-control');
        skipToControls.onclick = function(event) { event.preventDefault(); controlsLabel.focus(); };
        rootContainer.insertBefore(skipToControls, rootContainer.firstChild);
      }

      let skipToStart = document.createElement('a');
      skipToStart.innerHTML = leaflet.skipToStart;
      skipToStart.href = '#' + mapContainer.id;
      skipToStart.classList.add('leaflet-skip-to-start');
      skipToStart.onclick = function(event) { event.preventDefault(); mapContainer.focus(); };
      rootContainer.insertBefore(skipToStart, mapContainer.nextSibling);

    }


    //
    // Karte erstellen
    let initLat = rootContainer.getAttribute('data-init-lat');
    let initLon = rootContainer.getAttribute('data-init-lon');
    let initZoom = rootContainer.getAttribute('data-init-zoom');

    // diverse Karten als Übersicht: https://leaflet-extras.github.io/leaflet-providers/preview/
    let baseLayer = L.tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png', {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'});

    let map = L.map('map-extended' + ce, {
      layers: [baseLayer].concat(layers,markers),
      zoomControl: false,
      scrollWheelZoom: false,
      sleep: true,
      sleepOpacity: .6,
      sleepNoteStyle: {
        maxWidth: '10rem',
        opacity: 1
      },
      wakeMessage: leaflet.wakeMessage,
      wakeMessageTouch: leaflet.wakeMessageTouch
    })

    map.on('load',mapOnLoad);

    let baseLayers = {
      [leaflet.layerBaseMap]: baseLayer
    };

    L.control.layers(baseLayers,controlLayers,{
      collapsed: false
    }).addTo(map);

    L.control.zoom({
      zoomInTitle: leaflet.zoomInTitle,
      zoomOutTitle: leaflet.zoomOutTitle
    }).addTo(map);

    // nach dieser Anweisung wird der Load-Event ausgeführt
    map.setView([initLat,initLon], initZoom);
    map.fitBounds(markers.getBounds());
    

    //
    // sind noch Korrekturen nötig?

    // aria-label an einer section ergänzen
    let layerFilterSection = mapContainer.querySelector('section.leaflet-control-layers-list');
    layerFilterSection.setAttribute('aria-label',leaflet.layerFilterLabel);

    // Attribut aria-haspopup wird entfernt, wenn der Container immer geöffnet ist
    mapContainer.querySelector('.leaflet-control-layers-expanded').removeAttribute('aria-haspopup');

    // div im label des Layer-Selectors entfernen
    let layerFilter = mapContainer.querySelectorAll('.leaflet-control-layers-selector');
    for (let i = 0; i < layerFilter.length; i++) {
      let oldDad = layerFilter[i].parentNode;
      let newDad = layerFilter[i].parentNode.parentNode;
      let childElements = oldDad.childNodes.length;
      for (let j = 0; j < childElements; j++) {
        newDad.appendChild(oldDad.childNodes[0]);
      }
      newDad.removeChild(oldDad);
    }

    // aria-label am Schließen-Button ergänzen
    // focus auf das Fenster legen
    map.on('popupopen', function(event) {

      event.popup._closeButton.setAttribute('aria-label',leaflet.closePopupLabel);
      let clickedMarker = event.popup._source._icon;
      event.popup._closeButton.addEventListener('click',function(event){
        clickedMarker.focus();
      },{once:true});

      event.popup._wrapper.setAttribute('tabindex','0');
      event.popup._wrapper.focus();

    });

    // Aria-Role für die Marker
    // Text zum Beheben von Fehlern in NVDA
    var marker = mapContainer.querySelectorAll('.leaflet-marker-icon.leaflet-interactive');
    for (let i = 0; i < marker.length; i++) {
      marker[i].setAttribute('role','button');
    }


  }

});


