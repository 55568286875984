


// ------------------------------------------
// Input mit animiertem Label

ready(function() {

  let flyingLabel = function (element) {
    if (element.value == '') {
      element.closest('.form-flying-label').classList.remove('js-filled');
      element.closest('.form-flying-label').classList.remove('js-autofill');
    } else {
      element.closest('.form-flying-label').classList.add('js-filled');
    }
  }

  let flyingLabelInputs = document.querySelectorAll('.page-newsletter input, form.filter .filter-string input');
  let l;

  for (l = 0; l < flyingLabelInputs.length; l++) {

    // Überwachung der Eingaben
    flyingLabelInputs[l].parentNode.classList.add('form-flying-label');
    flyingLabelInputs[l].onfocus = function(event) { event.srcElement.closest('.form-flying-label').classList.add('js-focus') }
    flyingLabelInputs[l].onblur = function(event) { event.srcElement.closest('.form-flying-label').classList.remove('js-focus'); flyingLabel(event.srcElement); }
    flyingLabelInputs[l].onkeypress = function(event) { if (event.keyCode == '13') { flyingLabel(event.srcElement); } }
    flyingLabelInputs[l].onchange = function(event) { flyingLabel(event.srcElement); }
    flyingLabelInputs[l].oninput = function(event) { flyingLabel(event.srcElement); }

    // Reset
    flyingLabelInputs[l].closest('form').addEventListener('reset', function(event){
      window.setTimeout(function(){
        let flyingLabelInputs2 = event.srcElement.querySelectorAll('form .form-flying-label input, form .form-flying-label textarea');
        let l2;
        for (l2 = 0; l2 < flyingLabelInputs2.length; l2++) {
          flyingLabel(flyingLabelInputs2[l2]);
        }
      },false);
    });

    // Autofill
    flyingLabelInputs[l].addEventListener('animationstart', function(event){
      let flyingLabelInputs3 = event.srcElement.closest('form').querySelectorAll('form .form-flying-label input:-webkit-autofill, form .form-flying-label textarea:-webkit-autofill');
      let l3;
      for (l3 = 0; l3 < flyingLabelInputs3.length; l3++) {
        flyingLabelInputs3[l3].closest('.form-flying-label').classList.add('js-autofill');
      }
    },false);

    // Initial
    flyingLabel(flyingLabelInputs[l]);

  }

});


