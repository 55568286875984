


// ------------------------------------------
// einfache Leaflet-Karte
ready(function() {

  let mapCE = document.querySelectorAll('.map');


  for (let ce = 0; ce < mapCE.length; ce++) {

    let rootContainer = mapCE[ce];

    //
    // Zielcontainer für Karte erstellen und im Dom einfügen
    let mapContainer = document.createElement('div');
    mapContainer.id = 'map' + ce;

    rootContainer.insertBefore(mapContainer, rootContainer.firstChild);


    //
    // Layer und Marker erstellen
    let markerItems = rootContainer.querySelectorAll('.map-marker');
    let layer;

    if ( markerItems.length > 0 ) {

      // Layer erstellen
      layer = L.layerGroup();

      let m;
      for (m = 0; m < markerItems.length; m++) {

        let markerLon = markerItems[m].getAttribute('data-lon');
        let markerLat = markerItems[m].getAttribute('data-lat');
        let markerIcon = markerItems[m].getAttribute('data-icon');
        let markerIconWidth = markerItems[m].getAttribute('data-icon-width');
        let markerIconHeight = markerItems[m].getAttribute('data-icon-height');

        // Quellelement ausblenden
        markerItems[m].setAttribute('aria-hidden','true');

        // Icon
        let icon = L.icon({
          iconUrl: markerIcon,
          iconSize: [markerIconWidth, markerIconHeight]
        });

        let markerObj = L.marker([markerLat, markerLon],{icon: icon}).bindPopup(markerItems[m].innerHTML).addTo(layer)
        markerObj.addEventListener('click',function(event){
          this.openPopup();
        });

      }

    }


    //
    // Karte erstellen
    let initLat = rootContainer.getAttribute('data-init-lat');
    let initLon = rootContainer.getAttribute('data-init-lon');
    let initZoom = rootContainer.getAttribute('data-init-zoom');

    let baseLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'});

    let map = L.map('map' + ce, {
      layers: (layer) ? [baseLayer, layer] : [baseLayer],
      zoomControl: false,
      scrollWheelZoom: false,
      sleep: true,
      sleepOpacity: .9,
      sleepNoteStyle: {
        maxWidth: '10rem',
        opacity: 1
      },
      wakeMessage: leaflet.wakeMessage,
      wakeMessageTouch: leaflet.wakeMessageTouch
    });

    L.control.fullscreen({
      position: 'topleft',
      title: leaflet.fullScreenTitle,
      titleCancel: leaflet.fullScreenTitleCancel
    }).addTo(map);

    map.on('enterFullscreen', function(event){
      mapContainer.classList.add('leaflet-fullscreen');
    });

    map.on('exitFullscreen', function(event){
      mapContainer.classList.remove('leaflet-fullscreen');
    });

    // map.on('load',mapOnLoad);

    L.control.zoom({
      zoomInTitle: leaflet.zoomInTitle,
      zoomOutTitle: leaflet.zoomOutTitle
    }).addTo(map);

    // nach dieser Anweisung wird der Load-Event ausgeführt
    map.setView([initLat,initLon], initZoom);

    // Viewport neu setzten, falls die Karte in einem details-Element liegt und dieses geöffnet wird
    if ( rootContainer.closest('details') ) {
      var detailsContainer = rootContainer.closest('details');
      detailsContainer.addEventListener('toggle', function(event) {
        if (detailsContainer.hasAttribute('open')) {
          map.invalidateSize();
        }
      });
    }


    //
    // Flächen laden und erstellen
    if ( rootContainer.hasAttribute('data-shapes') ) {
      
      fetch(rootContainer.getAttribute('data-shapes'), {
        method: 'GET',
        headers: { 'Accept': 'application/json', },
      })
      .then((response) => response.json())
      .then(function(data){ 

        let coordinates = [];

        for (let i = 0; i < data.features.length; i++) {

          coordinates[i] = data.features[i].geometry.coordinates[0][0];

          for (let j = 0; j < coordinates[i].length; j++) {
            coordinates[i][j] = coordinates[i][j].reverse();
          }

          let bebaubarkeit = '';

          if ( data.features[i].properties['Nutzung (FNP)'] != null) {
            bebaubarkeit = data.features[i].properties['Nutzung (FNP)'];
          }

          if ( data.features[i].properties['Bebaubarkeit'] != null) {
            bebaubarkeit = bebaubarkeit + '<br/>' + data.features[i].properties['Bebaubarkeit'];
          }

          if ( data.features[i].properties['Bezeichnung'] != null) {
            bebaubarkeit = bebaubarkeit + '<br/>' + data.features[i].properties['Bezeichnung'];
          }

          let popupContent = '<p><strong class="highlighted">'
          + data.features[i].properties['Gemarkung']
          + ', ' + data.features[i].properties['Straße'] + '</strong></p>'
          + '<table>'
          + '<tr><th>Gemarkung:</th><td>'    + data.features[i].properties['Gemarkung'] + '</td></tr>'
          + '<tr><th>Flurstück:</th><td>'    + data.features[i].properties['Flurstück'] + '</td></tr>'
          + '<tr><th>Fläche:</th><td>'       + data.features[i].properties['Fläche m2'] + 'qm</td></tr>'
          + '<tr><th>Bebaubarkeit:</th><td>' + bebaubarkeit + '</td></tr>'
          + '</table>';


          if (rootContainer.hasAttribute('data-form-request')) {
            var url = new URL(rootContainer.getAttribute('data-form-request'));
            if (rootContainer.hasAttribute('data-formfield-request-gemarkung')) {
              url.searchParams.append(rootContainer.getAttribute('data-formfield-request-gemarkung'), data.features[i].properties['Gemarkung']);
            }
            if (rootContainer.hasAttribute('data-formfield-request-strasse')) {
              url.searchParams.append(rootContainer.getAttribute('data-formfield-request-strasse'), data.features[i].properties['Straße']);
            }
            if (rootContainer.hasAttribute('data-formfield-request-flurstueck')) {
              url.searchParams.append(rootContainer.getAttribute('data-formfield-request-flurstueck'), data.features[i].properties['Flurstück']);
            }
            if (rootContainer.hasAttribute('data-formfield-request-flaeche')) {
              url.searchParams.append(rootContainer.getAttribute('data-formfield-request-flaeche'), data.features[i].properties['Fläche m2']);
            }
            popupContent = popupContent + '<p><a href="' + url.href + '" class="btn">Fläche anfragen</a></p>';
          }

          if (rootContainer.hasAttribute('data-form-optout')) {
            var url = new URL(rootContainer.getAttribute('data-form-optout'));
            if (rootContainer.hasAttribute('data-formfield-optout-gemarkung')) {
              url.searchParams.append(rootContainer.getAttribute('data-formfield-optout-gemarkung'), data.features[i].properties['Gemarkung']);
            }
            if (rootContainer.hasAttribute('data-formfield-optout-strasse')) {
              url.searchParams.append(rootContainer.getAttribute('data-formfield-optout-strasse'), data.features[i].properties['Straße']);
            }
            if (rootContainer.hasAttribute('data-formfield-optout-flurstueck')) {
              url.searchParams.append(rootContainer.getAttribute('data-formfield-optout-flurstueck'), data.features[i].properties['Flurstück']);
            }
            if (rootContainer.hasAttribute('data-formfield-optout-flaeche')) {
              url.searchParams.append(rootContainer.getAttribute('data-formfield-optout-flaeche'), data.features[i].properties['Fläche m2']);
            }
            popupContent = popupContent + '<a href="' + url.href + '" class="xs">Gegen die Veröffentlichung widersprechen</a>';
          }

          let polygon = L.polygon(coordinates[i]).bindPopup(popupContent).addTo(map);

          polygon.addEventListener('click',function(event){
            this.openPopup();
          });

        }

        map.fitBounds(L.latLngBounds(coordinates));

      })
      .catch(function(error) {
        console.log(error);
      });


    }


  }


});


