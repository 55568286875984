"use strict";


class pxDropdownNavigation {


  constructor (navigationNodeList, settings = {}) {

    this.defaults = {
      'addButton'         : false,
      'addLabel'          : false,
      'btnLabelOpen'      : 'Navigationsbereich anzeigen',
      'btnLabelClose'     : 'Navigationsbereich verbergen',
      'btnTextOpen'       : '+',
      'btnTextClose'      : '×',
      'prefix'            : 'px',
      'targetContainer'   : '.dropdown',
      'event'             : 'mouseover', // click OR mouseover
      'openMultiple'      : false // true OR false
    };

    // Config erstellen
    this.config = Object.assign(this.defaults,settings);

    // Timeout-Speicher
    this.timeout = '';

    // ein Klick löst sowohl einen click-Event als auch einen focus-Event aus
    // bei bestimmten Konstellationen macht der zweite Event die Einstellungen des ersten rückgängig
    // um dies zu verhindern, wird in dieser Variable der Status gespeichert
    this.preventEventDoubling;

    // die meisten Browser führen bei einem click-Event auch ein focus-Event aus
    // bei einigen Browsern, z.B. Safari, ist dies jedoch nicht der Fall
    // diese Variable speichert das Verhalten des aktuellen Browsers und berücksichtigt dieses im click-Event
    this.focusOnClick = true;

    // Navigation
    this.navigationNodeList = navigationNodeList;

    // Dropdown Container auslesen
    this.tree = [];
    if ( this.navigationNodeList.length > 0 ) {

      let rootNodeList = this.navigationNodeList;
      let index = 0;

      for(let i = 0; i < rootNodeList.length; i++) {

        if ( rootNodeList[index].hasChildNodes() ) {

          // Parent-Container speichern
          this.tree[index] = { 'parent' : rootNodeList[i] };

          // Kind-Elemente speichern
          let nodeList = rootNodeList[i].children;

          // Target-Container speichern
          let targetID = this.config.prefix + i + 'target';
          let target = rootNodeList[i].querySelector( this.config.targetContainer );
          if ( target != null ) {
            target.id = targetID;
            this.tree[index].target = target;
          } else {
            this.tree[index].target = false;
            continue;
          }

          // eigenen Button erstellen oder vorhandenen Link verwenden?
          if ( this.config.addButton === true ) {

            // Link mit ID anreichern
            for(let j = 0; j < nodeList.length; j++) {
              if ( nodeList[j].nodeName == 'A' || nodeList[j].nodeName == 'STRONG' ) {
                nodeList[j].setAttribute('id',this.config.prefix + i + 'link');
              }
            }

            // Button erstellen
            let buttonID = this.config.prefix + i + 'btn';
            let button = document.createElement('button');
            button.innerHTML = this.config.btnTextOpen;
            this.setAttributes(button, {
              'aria-describedby': this.config.prefix + i + 'link',
              'class': 'js-btn',
              'id': buttonID,
            });
            target.parentNode.insertBefore(button,target);
            this.tree[index].button = button;

          } else {

            // vorhandenen Link als Button speichern
            for(let j = 0; j < nodeList.length; j++) {
              if ( nodeList[j].nodeName == 'A' || nodeList[j].nodeName == 'STRONG' ) {
                this.tree[index].button = nodeList[j];
                nodeList[j].id = this.config.prefix + i + 'btn';
                nodeList[j].setAttribute('role','button');
              }
            }

          }

          // Links im Dropdown als Array speichern
          if ( this.tree[index].target !== false ) {
            let children = this.tree[index].target.querySelectorAll('a'); // würde aktuell auch noch Links in der dritten Ebene finden
            if ( children.length > 0 ) {
              this.tree[index].children = Array.prototype.slice.call( children );
            } else {
              this.tree[index].children = false;
            }
          }

          // gibt es keinen Link und kein Dropdown -> Element entfernen
          if ( this.tree[index].button.nodeName == 'STRONG' && this.tree[index].target === false ) {
            this.tree.pop();
            continue;
          }

          // gibt es ein Dropdown, muss das strong per TAB erreichbar sein
          if ( this.tree[index].button.nodeName == 'STRONG' ) {
            this.tree[index].button.setAttribute('tabindex','0');
          }

          // Index erhöhen
          index++;

        }

      }

    }

    // Aria ergänzen
    this.addAria(this.tree);

    // Events anbinden
    this.bindEvents(this.tree);

  }


  // Änderungen an der Config
  setOpenMultiple (value) {
    this.config.openMultiple = value;
  }


  addAria (tree) {

    for ( let i = 0; i < tree.length; i++ ) {
      if (tree[i].target !== false) {

        this.setAttributes(tree[i].button,{
          'aria-controls': tree[i].target.id,
          'aria-haspopup':'true',
          'aria-expanded':'false',
          'tabindex' : 0
        });

        if (this.config.addLabel === true) {
          tree[i].button.setAttribute('aria-label',this.config.btnLabelOpen);
        }

        this.setAttributes(tree[i].target,{
          'aria-labelledby': tree[i].button.id,
          'aria-hidden':'true',
          'role':'menu'
        });

        let li = tree[i].target.querySelectorAll('li');
        let index = 0;
        for( index=0; index < li.length; index++ ) {
          li[index].setAttribute('role','none');
        }

        let menuitem = tree[i].target.querySelectorAll('a,strong');
        index = 0;
        for( index=0; index < menuitem.length; index++ ) {
          menuitem[index].setAttribute('role','menuitem');
        }

      }
    }

  }


  bindEvents (tree) {

    // Schließt ein geöffnetes Overlay beim Klick ins Dokument
    // if ( this.config.event == 'mouseover' ) {
      function closeDropdown (event,container) {
        let toggleElements = false;
        if (event.type == 'focusout') {
          if (event.relatedTarget && !container.contains(event.relatedTarget)) {
            toggleElements = true;
          }
        } else if (!container.contains(event.target)) {
          toggleElements = true;
        }
        if (toggleElements) {
          let containerElements;
          if (containerElements = container.querySelector('[aria-expanded="true"]')) {
            containerElements.setAttribute('aria-expanded','false');
          }
          if (containerElements = container.querySelector('[aria-hidden="false"]')) {
            containerElements.setAttribute('aria-hidden','true');
          }
        }
      }

      for ( let i = 0; i < this.navigationNodeList.length; i++ ) {
        document.addEventListener('touchstart', (event) => closeDropdown(event,this.navigationNodeList[i].parentNode) );
        document.addEventListener('click', (event) => closeDropdown(event,this.navigationNodeList[i].parentNode) );
        
        // Schließt ein geöffnetes Overlay Focus Out
        this.navigationNodeList[i].parentNode.addEventListener('focusout', (event) => closeDropdown(event,this.navigationNodeList[i].parentNode) );
      }

    // }

    for ( let i = 0; i < tree.length; i++ ) {

      if ( tree[i].button == undefined ) {
        break;
      }

      // Tastatur: Teil 1 für alle Elemente in Stufe 1
      tree[i].button.addEventListener('keydown', (event) => {

        // Pfeil zurück
        if (event.keyCode === 37) {
          event.preventDefault();
          this.showPrevDropdown(event.target.parentNode);
        }

        // Pfeil vor
        if (event.keyCode === 39) {
          event.preventDefault();
          this.showNextDropdown(event.target.parentNode);
        }

      });

      // Abbruch falls es kein Dropdown gibt
      if (tree[i].target === false) {
        continue;
      }

      if ( this.config.event == 'mouseover' ) {

        // Touch: Fallback für Touch-Geräte wie Android
        tree[i].parent.addEventListener('touchstart', (event) => {
          if (event.target.getAttribute('aria-expanded') == 'false') {

            event.preventDefault();

            event.target.parentNode.parentNode.classList.add('touchopen');

            this.toggleDropdown(event.target.parentNode,true);

            if ( this.config.openMultiple === false ) {
              this.closeOthers(event.target.parentNode);
            }

          } else {
            event.target.parentNode.parentNode.classList.remove('touchopen');
            this.toggleDropdown(event.target.parentNode,false);
          }
          
        });

        // Maus: Dropdown öffnen
        tree[i].parent.addEventListener('mouseenter', (event) => {
          this.toggleDropdown(event.target,true);
        });

        // Maus: Dropdown schließen
        tree[i].parent.addEventListener('mouseleave', (event) => {
          this.toggleDropdown(event.target,false);
        });

      } else if ( this.config.event == 'click' ) {
        
        // Maus oder Touch: Dropdown öffnen
        tree[i].button.addEventListener('click', (event) => {
          
          event.preventDefault();

          if ( this.config.openMultiple === false ) {
            this.closeOthers(event.target.parentNode);
          }

          if ( this.preventEventDoubling == undefined ) {
            this.focusOnClick = false;
          }

          if (this.focusOnClick) {
            if ( this.preventEventDoubling != event.target ) {
              if ( event.target.getAttribute('aria-expanded') == 'true' ) {
                this.toggleDropdown(event.target.parentNode,false);
              }
              this.preventEventDoubling = event.target;
            } else {
              this.toggleDropdown(event.target.parentNode,true);
              this.preventEventDoubling = false;
            }
          } else {
            if ( event.target.getAttribute('aria-expanded') == 'true' ) {
              this.toggleDropdown(event.target.parentNode,false);
            } else {
              this.toggleDropdown(event.target.parentNode,true);
            }
          }

        });

      }

      // Fokus: Dropdown öffnen
      tree[i].button.addEventListener('focus', (event) => {
        if ( event.target.getAttribute('aria-expanded') == 'true' ) {
          this.toggleDropdown(event.target.parentNode,false);
        } else {
          this.toggleDropdown(event.target.parentNode,true);
          this.preventEventDoubling = event.target;
        }
        if ( this.config.openMultiple === false ) {
          for ( i = 0; i < tree.length; i++ ) {
            if (tree[i].parent != event.target.parentNode) {
              this.toggleDropdown(tree[i].parent,false);
            }
          }
        }
      });

      // Tastatur: Teil 2
      tree[i].button.addEventListener('keydown', (event) => {
        
        // Pfeil nach unten
        if (event.keyCode === 40) {
          event.preventDefault();
          this.setFocusToFirstInDropdown(event.target.parentNode);
        }

        // Pfeil nach oben
        if (event.keyCode === 38) {
          event.preventDefault();
          this.toggleDropdown(event.target.parentNode,false);
        }

        // Enter
        if (event.keyCode === 13) {
          if ( event.target.getAttribute('aria-expanded') == 'true' ) {
            this.preventEventDoubling = false;
          } else {
            this.preventEventDoubling = event.target;
          }
          if (event.target.nodeName == 'STRONG' ) {
            event.target.click();
          }
        }

        // ESC - Dropdown schließen
        let key = event.key || event.keyCode;
        if (key === 'Escape' || key === 'Esc' || key === 27) {
          event.preventDefault();
          this.toggleDropdown(event.target.parentNode,false);
          this.preventEventDoubling = event.target;
        }

      });


      // Elemente im Dropdown
      for ( let c = 0; c < tree[i].children.length; c++ ) {

        // Fokus: Dropdown schließen
        // falls der Link nicht im aktuell geöffneten Dropdown liegt
        tree[i].children[c].addEventListener('blur', (event) => {
          let index = this.getParentNumOfDropdownLink(event.target);
          this.toggleDropdown(tree[index].parent,false);
        });

        // Fokus: Dropdown öffnen
        // falls der Link im aktuell geöffneten Dropdown liegt
        tree[i].children[c].addEventListener('focus', (event) => {
          let index = this.getParentNumOfDropdownLink(event.target);
          this.toggleDropdown(tree[index].parent,true);
        });

        // Tastatur
        tree[i].children[c].addEventListener('keydown', (event) => {
        
          // Pfeil zurück
          if (event.keyCode === 37) {
            event.preventDefault();
          }

          // Pfeil vor
          if (event.keyCode === 39) {
            event.preventDefault();
          }

          // Pfeil nach unten
          if (event.keyCode === 40) {
            event.preventDefault();
            this.setFocusToNextDropdownLink(event.target);
          }

          // Pfeil nach oben
          if (event.keyCode === 38) {
            event.preventDefault();
            this.setFocusToPrevDropdownLink(event.target);
          }

          // ESC - Fokus zurück in die erste Stufe der Navigation + Dropdown schließen
          let key = event.key || event.keyCode;
          if (key === 'Escape' || key === 'Esc' || key === 27) {
            event.preventDefault();
            let currentButton = this.tree[this.getParentNumOfDropdownLink(event.target)].button
            currentButton.focus();
            this.preventEventDoubling = currentButton;
          }

        });

      }

    }

  }


  // öffnet oder schließt das Dropdown
  // übergeben wird der umschließende Container
  toggleDropdown (parent,show) {

    let index = this.tree.findIndex(item => item.parent === parent);

    if (this.tree[index].target === false) {
      return;
    }

    for ( let c = 0; c < this.tree[index].children.length; c++ ) {
      if ( this.tree[index].children[c] === document.activeElement ) {
        clearTimeout(this.timeout);
      }
    }

    if ( show === false ) {
      let t = this;
      let c = this.config;
      this.timeout = setTimeout( function() {
        if (c.addLabel === true) {
          t.tree[index].button.setAttribute('aria-label',t.config.btnLabelOpen);
        }
        t.tree[index].button.setAttribute('aria-expanded','false');
        t.tree[index].target.setAttribute('aria-hidden','true');
      }, 100);
    } else {
      if (this.config.addLabel === true) {
        this.tree[index].button.setAttribute('aria-label',this.config.btnLabelClose);
      }
      this.tree[index].button.setAttribute('aria-expanded','true');
      this.tree[index].target.setAttribute('aria-hidden','false');
    }

  }


  // schließt alle Dropdown außer den übergebenen
  // nur nötig mit click
  closeOthers (parent) {

    // Einstellungen prüfen
    if ( this.config.openMultiple === false ) {

      for ( let c = 0; c < this.tree.length; c++ ) {

        if ( this.tree[c].parent !== parent && this.tree[c].target !== false ) {
          if (this.config.addLabel === true) {
            this.tree[c].button.setAttribute('aria-label',this.config.btnLabelOpen);
          }
          this.tree[c].button.setAttribute('aria-expanded','false');
          this.tree[c].target.setAttribute('aria-hidden','true');
        }

      }

    }
    
  }


  // wechselt zum vorherigen oder letzten Hauptnavigationspunkt
  showPrevDropdown (parent) {
    let index = this.tree.findIndex(item => item.parent === parent);
    if ( index == 0 ) {
      index = (this.tree.length - 1);
    } else {
      index = index - 1;
    }
    this.tree[index].button.focus();
  }


  // wechselt zum nächsten oder ersten Hauptnavigationspunkt
  showNextDropdown (parent) {
    let index = this.tree.findIndex(item => item.parent === parent);
    if ( index == (this.tree.length - 1) ) {
      index = 0;
    } else {
      index = index + 1;
    }
    this.tree[index].button.focus();
  }


  // setzt den Focus auf den ersten Link im geöffneten Dropdown
  setFocusToFirstInDropdown (parent) {
    let index = this.tree.findIndex(item => item.parent === parent);
    this.toggleDropdown(parent,true);
    this.tree[index].children[0].focus();
  }


  // setzt den Fokus auf den vorherigen oder letzten Link im Dropdown
  setFocusToPrevDropdownLink (linkNode) {
    let index = this.getParentNumOfDropdownLink (linkNode);
    let posInChildren = this.tree[index].children.findIndex(item => item === linkNode);
    if ( posInChildren == 0 ) {
      posInChildren = ( this.tree[index].children.length - 1);
    } else {
      posInChildren = posInChildren - 1;
    }
    this.tree[index].children[posInChildren].focus();
  }


  // setzt den Fokus auf den nächsten oder ersten Link im Dropdown
  setFocusToNextDropdownLink (linkNode) {
    let index = this.getParentNumOfDropdownLink (linkNode);
    let posInChildren = this.tree[index].children.findIndex(item => item === linkNode);
    if ( posInChildren == (this.tree[index].children.length - 1) ) {
      posInChildren = 0;
    } else {
      posInChildren = posInChildren + 1;
    }
    this.tree[index].children[posInChildren].focus();
  }


  // findet heraus, in welchem Dropdown der übergebene Link liegt
  getParentNumOfDropdownLink (linkNode) {
    let index;
    for ( let j = 0; j < this.tree.length; j++ ) {
      if ( this.tree[j].target !== false && this.tree[j].children !==false ) {
        if ( this.tree[j].children.indexOf(linkNode) != -1 ) { index = j; }
      }
    }
    return index;
  }


  // Hilfsfunktion
  // setzt mehrere Attribute
  setAttributes (element, attributes) {
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
  }


}


export default pxDropdownNavigation;

