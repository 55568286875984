/*
 *
 * Stadt Riesa - JS: JS Base
 *
 * @author Danny Otto | pixelbrand.net
 * @date   2021-07
 *
 */
 


// ------------------------------------------
// no-js - falls modernizr deaktiviert wird
document.documentElement.className = document.documentElement.className.replace('no-js','js');
 


// ------------------------------------------
// sticky hover fix für iOS 
(function(l){var i,s={touchend:function(){}};for(i in s)l.addEventListener(i,s)})(document);



// ------------------------------------------
// Dom ready
// use: ready(function() { alert('hello'); });
window.ready = function(fn) {
  if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}



// ------------------------------------------
// Polyfills

// assign (pxEasyExpand IE11)
require('./polyfills/assign.js');

// fetch
require('./polyfills/fetch.js');

// findIndex
require('./polyfills/findindex.js');

// details/summary (IE11)
ready(function() {
  require('./polyfills/details.js');
});

// swiper (IE11)
require('./polyfills/append.js');
require('./polyfills/CustomEvent.js');
require('./polyfills/isNaN.js');
require('./polyfills/repeat.js');



// ------------------------------------------
// Lazyloading ( npm install lazysizes --save )
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';

lazySizes.cfg.nativeLoading = {
  setLoadingAttribute: true,
  disableListeners: {
    scroll: true
  },
};

lazySizes.cfg.lazyClass = 'js-load';
lazySizes.cfg.loadingClass = 'js-loading';
lazySizes.cfg.loadedClass = 'js-loaded';
lazySizes.cfg.autosizesClass = 'js-autosizes';



// ------------------------------------------
// Sticky
// if ('IntersectionObserver' in window) {
//   const header = document.querySelector('.page-header');
//   const headerObserver = new IntersectionObserver(
//     ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
//     { threshold: [1] }
//   );
//   headerObserver.observe(header);
// }



// ------------------------------------------
// Partials

// Formulare
require('./scripts/form-flying-label.js');

// Toplink
require('./partials/toplink.js');

// Accordion
ready(function() {
  require('./scripts/aria-accordion.js');
});



// ------------------------------------------
// Autocomplete
// http://autocomplete-js.com/#documentation

// window.AutoComplete = require('./scripts/autocomplete.js');

// ready(function() {

  // AutoComplete();

  // AutoComplete({

  //   _Focus: function() {

  //     var event = new Event('position');
  //     document.querySelector('[data-autocomplete]').dispatchEvent(event);

  //   }
  //   
  // });

// });



// ------------------------------------------
// Lightbox
import baguetteBox from './modules/baguettebox.js';

ready(function() {

  var lightbox = baguetteBox.run('.tm-images',{
    animation: 'fadeIn',
    filter: /.+\.(gif|jpe?g|png|webp)/i,
    overlayBackgroundColor: '',
    closeX: window.lightbox.closeX,
    leftArrow: window.lightbox.leftArrow,
    rightArrow: window.lightbox.rightArrow
  });

  if (lightbox.length > 0) {

    document.getElementById('close-button').setAttribute('aria-label', window.lightbox.close);
    document.getElementById('previous-button').setAttribute('aria-label', window.lightbox.prev);
    document.getElementById('next-button').setAttribute('aria-label', window.lightbox.next);

    document.getElementById('previous-button').addEventListener('keydown', function (event){
      if (event.shiftKey && event.keyCode == 9) { 
        event.preventDefault();
        document.getElementById('close-button').focus();
      }
    });

  }

});



// ------------------------------------------
// Maps auf Basis von Leaflet und OpenStreetMap
import L from './modules/leaflet/leaflet.js';
require('./modules/leaflet/leaflet.control.fullscreen.js');
require('./modules/leaflet/leaflet.sleep.js');
require('./modules/leaflet/leaflet.markercluster.js');
require('./modules/leaflet/leaflet.markercluster.layersupport.js');
// require('./modules/leaflet/leaflet.LatLng.UTM.js');
require('./scripts/leaflet-simple.js');
require('./scripts/leaflet-extended.js');

ready(function() {

  let mapLayers = document.querySelectorAll('.leaflet-control-layers-overlays');

  let toggleLabels = function(labels,status) {
    let labelArray = labels.split(' ');
    for (let i = 0; i < labelArray.length; i++) {
      document.getElementById(labelArray[i]).setAttribute('aria-hidden',status);
    }
  }

  for (let i = 0; i < mapLayers.length; i++) {
    
    let mapLayersLabels = mapLayers[i].querySelectorAll('label');

    if ( mapLayersLabels.length > leaflet.layerFilterMaxInitialOptions) {

      let labelIDs = [];

      let button = document.createElement('button');
      button.innerHTML = leaflet.layerFilterButtonTextOpen;
      button.setAttribute('class', 'leaflet-control-layers-button');
      button.setAttribute('type', 'button');
      button.setAttribute('aria-label',leaflet.layerFilterButtonLayerOpen);
      mapLayers[i].append(button);

      for (let l = leaflet.layerFilterMaxInitialOptions; l < mapLayersLabels.length; l++) {
        mapLayersLabels[l].id = 'label' + i + l;
        mapLayersLabels[l].setAttribute('aria-hidden','true');
        labelIDs.push('label' + i + l);
      }

      let ariaControls = labelIDs.join(' ');
      button.setAttribute('aria-controls', ariaControls);
      button.setAttribute('aria-expanded', 'false');

      button.addEventListener('click',function(event){

        if ( this.getAttribute('aria-expanded') == 'true' ) {
          this.setAttribute('aria-expanded', 'false');
          this.setAttribute('aria-label', leaflet.layerFilterButtonLabelOpen);
          this.innerHTML = leaflet.layerFilterButtonTextOpen;
          toggleLabels(this.getAttribute('aria-controls'),'true');
        } else {
          this.setAttribute('aria-expanded', 'true');
          this.setAttribute('aria-label', leaflet.layerFilterButtonLabelClose);
          this.innerHTML = leaflet.layerFilterButtonTextClose;
          toggleLabels(this.getAttribute('aria-controls'),'false');
        }

      });

    }

  }

});



// ------------------------------------------
// Tabs

import Tabby from './modules/tabby.polyfills.js';

ready(function() {

  let tabContainer = document.querySelectorAll('[data-tabs]')

  for (let i = 0; i < tabContainer.length; i++) {
    tabContainer[i].setAttribute('data-tabs-'+i, '');
    new Tabby('[data-tabs-'+i+']');
  }  

});



// ------------------------------------------
// Slider
import Swiper from './modules/swiper_6.8.2.js';

ready(function() {

  const sliderContainer = document.querySelectorAll('.ce-slider > ul, .page-hero-slider > ul');

  for (let i = 0; i < sliderContainer.length; i++) {

    let sliderUL = sliderContainer[i];
    let sliderParent = sliderUL.parentNode;
    let sliderItems = sliderUL.querySelectorAll('li');
    let autoplay = false;

    if ( sliderItems.length < 2 ) {
      continue;
    }

    if (sliderParent.hasAttribute('data-autoplay')) {
      autoplay = {delay:sliderParent.getAttribute('data-autoplay')};
    }

    sliderParent.classList.add('swiper-container');
    sliderUL.classList.add('swiper-wrapper');

    for (let j = 0; j < sliderItems.length; j++) {
      sliderItems[j].classList.add('swiper-slide');
    }

    let pagination = document.createElement('div');
    pagination.setAttribute('class', 'swiper-pagination');
    sliderParent.append(pagination);

    let buttonWrapper = document.createElement('div');
    buttonWrapper.setAttribute('class', 'swiper-controls');
    sliderParent.append(buttonWrapper);

    let buttonNext = document.createElement('button');
    buttonNext.innerHTML = swiper.next;
    buttonNext.setAttribute('class', 'swiper-next swiper-next-' + i);
    buttonNext.setAttribute('type', 'button');
    buttonNext.setAttribute('aria-label',swiper.nextTitle);
    buttonWrapper.insertBefore(buttonNext,buttonWrapper.firstChild);

    let buttonPrev = document.createElement('button');
    buttonPrev.innerHTML = swiper.prev;
    buttonPrev.setAttribute('class', 'swiper-prev swiper-prev-' + i);
    buttonPrev.setAttribute('type', 'button');
    buttonPrev.setAttribute('aria-label',swiper.prevTitle);
    buttonWrapper.insertBefore(buttonPrev,buttonWrapper.firstChild);

    let slider = new Swiper(sliderParent,{
      a11y: {
        enabled: false
      },
      allowTouchMove: true,
      autoHeight: true,
      autoplay: autoplay,
      cssMode: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false
      },
      loop: true,
      navigation: {
        nextEl: buttonNext,
        prevEl: buttonPrev
      },
      pagination: {
        clickable: false,
        el: pagination,
        type: 'bullets'
      },
      slidesPerView: 1,
      spaceBetween: 0
    });

    // Slider-Höhe nach dem Laden von Bildern anpassen
    let images = sliderContainer[i].querySelectorAll('img.js-load');
    for (let j = 0; j < images.length; j++) {
      images[j].addEventListener('load', (function(slider){
        return function(){
          slider.updateAutoHeight(200);
        }
      })(slider));
    }

  }

});

ready(function() {

  const sliderContainer = document.querySelectorAll('.nav-metro > ul');

  for (let i = 0; i < sliderContainer.length; i++) {

    let sliderUL = sliderContainer[i];
    let sliderParent = sliderUL.parentNode;
    let sliderItems = sliderUL.querySelectorAll('li');

    // if ( window.innerWidth >= 980 && sliderItems.length < 5 ) {
    //   continue;
    // }

    sliderParent.classList.add('swiper-container');
    sliderUL.classList.add('swiper-wrapper');

    for (let j = 0; j < sliderItems.length; j++) {
      sliderItems[j].classList.add('swiper-slide');
    }

    let pagination = document.createElement('div');
    pagination.setAttribute('class', 'swiper-pagination');
    sliderParent.append(pagination);

    let buttonWrapper = document.createElement('div');
    buttonWrapper.setAttribute('class', 'swiper-controls');
    sliderParent.append(buttonWrapper);

    let buttonNext = document.createElement('button');
    buttonNext.innerHTML = swiper.next;
    buttonNext.setAttribute('class', 'swiper-next swiper-next-' + i);
    buttonNext.setAttribute('type', 'button');
    buttonNext.setAttribute('aria-label',swiper.nextTitle);
    buttonWrapper.insertBefore(buttonNext,buttonWrapper.firstChild);

    let buttonPrev = document.createElement('button');
    buttonPrev.innerHTML = swiper.prev;
    buttonPrev.setAttribute('class', 'swiper-prev swiper-prev-' + i);
    buttonPrev.setAttribute('type', 'button');
    buttonPrev.setAttribute('aria-label',swiper.prevTitle);
    buttonWrapper.insertBefore(buttonPrev,buttonWrapper.firstChild);

    let slider = new Swiper(sliderParent,{
      a11y: {
        enabled: false
      },
      allowTouchMove: true,
      autoHeight: true,
      autoplay: false,
      cssMode: false,
      keyboard: {
        enabled: true,
        onlyInViewport: false
      },
      navigation: {
        nextEl: buttonNext,
        prevEl: buttonPrev
      },
      pagination: {
        clickable: false,
        el: pagination,
        type: 'bullets'
      },
      slidesPerView: 1,
      spaceBetween: 0,
      breakpoints: {
        480: {
          slidesPerView: 2,
          spaceBetween: 0
        },
        759: {
          slidesPerView: 3
        },
        979: {
          slidesPerView: 4
        }
      }
    });

    // Slider-Höhe nach dem Laden von Bildern anpassen
    let images = sliderContainer[i].querySelectorAll('img.js-load');
    for (let j = 0; j < images.length; j++) {
      images[j].addEventListener('load', (function(slider){
        return function(){
          slider.updateAutoHeight(200);
        }
      })(slider));
    }

  }

});



// ------------------------------------------
// Mobilnavigation mit Slideouts

import pxNavigationToggle from './modules/pxNavigationToogle.js';

ready(function() {

  const amt24toggle = new pxNavigationToggle('.amt24nav > ul',{
    'prefix' : 'pxa',
    'hiddenAttribute' : 'data-hidden'
  });

});



// ------------------------------------------
// Navigation mit Dropdown-Elementen
// und Tastatursteuerung

import pxDropdownNavigation from './modules/pxDropdownNavigation.js';

ready(function() {

  const navigation = new pxDropdownNavigation(document.querySelectorAll('.nav-main > ul > li'),{
    'addButton'       : false,
    'event'           : 'click',
    'targetContainer' : 'div.overlay',
    'prefix'          : 'pxr'
  });

});



// ------------------------------------------
// Hamburger und Mobilnavigation

import pxEasyExpand from './modules/pxEasyExpand.js';

ready(function() {
  
  // Hamburger
  const hamburger = new pxEasyExpand('#hamburger',{
    'closeWithEsc' : true,
    'setRootClass' : 'navigation-open',
    'addCloseButton' : false,
    'keepFocusInTargetElement' : true
  });
  
  // Suchbutton
  const searchbutton = new pxEasyExpand('#searchbutton',{
    'closeWithEsc' : true,
    'setRootClass' : 'searchform-open',
    'addCloseButton' : false,
    'setFocusToFirstElement' : true,
    'keepFocusInTargetElement' : true
  });

  // Formular-Infobutton
  let infoButton = document.querySelectorAll('form .info-button');
  for (let i = 0; i < infoButton.length; i++) {

    infoButton[i].id = 'infobutton' + i;

    let infoBtn = new pxEasyExpand('#infobutton' + i,{
      'closeWithEsc' : false,
      'addCloseButton' : false,
      'keepFocusInTargetElement' : false
    });

  }

  // Amt24
  let amt24nav = document.getElementById('amt24nav');
  let amt24btn = document.getElementById('amt24nav-button');

  if (amt24nav && amt24btn) {
    const amt24 = new pxEasyExpand('#amt24nav-button',{
      'addCloseButton' : false,
      'closeWithEsc' : false,
      'keepFocusInTargetElement' : false,
      'hiddenAttribute' : 'data-hidden'
    });
  }

});



// ------------------------------------------
// User Settings

import pxUserSetting from './modules/pxUserSetting.js';

ready(function() {
  const contrast = new pxUserSetting('.contrast a', {
    'name' : 'contrast'
  });
});



// ------------------------------------------
// einfache Slide-Navigation
ready(function() {

  let navBaseContainer = document.querySelector('#navigation');
  let navWrapper = navBaseContainer.querySelector('.nav-mobil-innerwrap');
  let nav = navBaseContainer.querySelector('nav');

  navWrapper.setAttribute('data-open','false');

  let firstLevel = navBaseContainer.querySelectorAll('nav > ul > li > a, nav > ul > li > strong');
  let firstLevelContainer = document.createElement('section');
  firstLevelContainer.setAttribute('aria-label',navBaseContainer.getAttribute('data-text-section'));

  firstLevelContainer.classList.add('nav-mobil-first-level');
  navWrapper.insertBefore(firstLevelContainer,nav);

  for (let i = 0; i < firstLevel.length; i++) {

    firstLevel[i].parentNode.id = 'nm-' + i;
    
    // Button zum Öffnen
    let firstLevelButton = document.createElement('button');
    firstLevelButton.id = 'nm-' + i + '-btn';
    firstLevelButton.setAttribute('data-label-close',navBaseContainer.getAttribute('data-label-close'));
    firstLevelButton.setAttribute('data-focus-helper-text', navBaseContainer.getAttribute('data-focus-helper-text'));
    firstLevelButton.setAttribute('data-controls','nm-' + i);
    firstLevelButton.innerHTML = firstLevel[i].innerHTML + '<span>- ' + navBaseContainer.getAttribute('data-label-open') + '</span>';
    firstLevelContainer.appendChild(firstLevelButton);

    let firstLevelButtonExpand = new pxEasyExpand('#nm-' + i + '-btn',{
      'closeWithEsc' : true,
      'setRootClass' : 'navigation-slide-open',
      'addCloseButton' : true,
      'setFocusToFirstElement' : false,
      'setFocusToElement' : 'button.js-close',
      'keepFocusInTargetElement' : true,
      'hiddenAttribute' : 'data-hidden'
    });

  }

});



// ------------------------------------------
// Listen mit Nachladefunktion (Ajax)
ready(function() {

  let moreButtons = document.querySelectorAll('[data-ajax-url]');

  for (let i = 0; i < moreButtons.length; i++) {

    let button = moreButtons[i];
    let list = button.parentNode.parentNode.querySelector('ul');

    // Button-Attribute speichern
    let ajaxUrl = button.getAttribute('data-ajax-url');
    let limit = parseInt(button.getAttribute('data-limit'));
    let offset = parseInt(button.getAttribute('data-offset'));

    // Status
    let urlHashPrefix = 'list-item-';
    let itemCount = 0;

    // Button: Aria-Label setzen
    button.setAttribute('aria-label', button.getAttribute('data-label'));

    // Button: data-Attribute entfernen
    button.removeAttribute('data-label');
    button.removeAttribute('data-ajax-url');
    button.removeAttribute('data-limit');
    button.removeAttribute('data-offset');

    // Funktion zum Nachladen der Einträge

    function loadMore (offset,limit,changeHistory) {

      let newAjaxUrl;

      if (ajaxUrl.indexOf('?') !== -1) {
        newAjaxUrl = ajaxUrl + '&ajax=1&offset=' + offset + '&limit=' + limit;
      } else {
        newAjaxUrl = ajaxUrl + '?ajax=1&offset=' + offset + '&limit=' + limit;
      }

      fetch(newAjaxUrl)
      .then((response) => response.json())
      .then((data) => {

        let newList = document.createElement('div');
        newList.innerHTML = data.content;

        // Füge neue Einträge zur Liste hinzu
        let items = newList.querySelectorAll('li');

        for (let i = 0; i < items.length; i++) {
          list.append(items[i]);
          itemCount++;
          items[i].id = urlHashPrefix + itemCount;
        }

        // Fokus auf den ersten neu geladenen Eintrag verschieben
        let target = items[0].querySelector('a');
        if (target) {
          target.focus();
        }

        // Entferne Button, falls keine weiteren Einträge vorhanden sind
        if (!data.meta.recordsLeft) {
          button.parentNode.remove();
        }

        // Scrolle zum ersten neuen Eintrag + Hash setzen
        // items[0].scrollIntoView();
        // document.location.hash = items[0].id;

        if ( changeHistory === true) {
          // console.log('changeHistory');
          history.pushState({}, '', window.location.origin + window.location.pathname + '#' + urlHashPrefix + itemCount);
        }

      })
      .catch((error) => {
        console.warn(error);
      });

    }

    // bei Klick weitere Einträge laden
    button.addEventListener('click', event => {

      event.preventDefault();

      console.log('offset:' + offset + ' / limit:' + limit);

      loadMore (offset,limit,true);

      offset = offset + limit;

    });


    // Einträge anhand des Hash in der URL laden

    // URL-Hash auslesen und prüfen ob alle angeforderten Einträge vorhanden sind
    let currentHash = window.location.hash.substring(1);

    if ( currentHash.startsWith(urlHashPrefix) && !document.getElementById(currentHash) ) {

      // console.log(currentHash);
      // console.log(currentHash.substring(urlHashPrefix.length));

      offset = limit;

      console.log('offset:' + offset + ' / limit:' + parseInt(currentHash.substring(urlHashPrefix.length)));

      loadMore (offset, parseInt(currentHash.substring(urlHashPrefix.length)), false);

      offset = parseInt(currentHash.substring(urlHashPrefix.length)) + limit;

    }


  } 

});



// ------------------------------------------
// Themes
ready(function() {

  let button = document.querySelector('.contrast a');
  let buttonTextElement = button.querySelector('span');

  button.addEventListener('click', event => {

    event.preventDefault();

    // normale Seite
    if ( !document.body.hasAttribute('data-theme') ) {

      if ( document.body.classList.contains('theme-contrast') ) {
        document.body.classList.remove('theme-contrast');
        buttonTextElement.textContent = button.getAttribute('data-text-1');
      } else {
        document.body.classList.add('theme-contrast');
        buttonTextElement.textContent = button.getAttribute('data-text-2');
      }

      return;

    }

    // Themen-Seite
    if ( document.body.classList.contains('theme-'+document.body.getAttribute('data-theme')) ) {

      document.body.classList.remove('theme-'+document.body.getAttribute('data-theme'));
      document.body.classList.add('theme-contrast');
      buttonTextElement.textContent = button.getAttribute('data-text-2');

    } else {

      for (let i = document.body.classList.length - 1; i >= 0; i--) {
        const className = document.body.classList[i];
        if (className.startsWith('theme-')) {
          document.body.classList.remove(className);
        }
      }

      document.body.classList.add('theme-'+document.body.getAttribute('data-theme'));
      buttonTextElement.textContent = button.getAttribute('data-text-1');

    }

  });

});


