


// --------------------------------------
// Toplink
ready(function() {


  // fade an element from the current state to full opacity in "duration" ms
  // function fadeOut(el, duration) {
  //     var s = el.style, step = 25/(duration || 300);
  //     s.opacity = s.opacity || 1;
  //     (function fade() { (s.opacity -= step) < 0 ? s.display = "none" : setTimeout(fade, 25); })();
  // }
  
  // fade out an element from the current state to full transparency in "duration" ms
  // display is the display style the element is assigned after the animation is done
  // function fadeIn(el, duration, display) {
  //     var s = el.style, step = 25/(duration || 300);
  //     s.opacity = s.opacity || 0;
  //     s.display = display || "block";
  //     (function fade() { (s.opacity = parseFloat(s.opacity)+step) > 1 ? s.opacity = 1 : setTimeout(fade, 25); })();
  // }


  var toplink = document.querySelectorAll('.toplink')[0];


  window.addEventListener('scroll', function() {
    if ( Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) > 100) {
      // fadeIn(toplink,400,'block');
      toplink.style.display = 'block';
    } else {
      // fadeOut(toplink,400);
      toplink.style.display = 'none';
    }
  });


  if ( Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) < 101) {
    toplink.style.display = 'none';
  }


});


